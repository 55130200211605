<template>
	<div>
		<div class="header">
			<div @click="$router.back(0)" class="titlename">{{ titlename }}></div>
			<div class="current">裁判详情</div>
		</div>
		<div class="back">
			<div class="theleftside">
				<div>
					<div class="title top">
						<img src="../assets/image/title1.png" alt="">
						<p>公示信息</p>
					</div>
					<div class="box">
						<div style="width: 100%;height: 50PX;"></div>
						<div class="box-back">
							<img :src="date.avatar" alt="" class="box-logo">
							<div class="box-title">
								<div class="box-name">姓名：{{ date.refereeName }}</div>
								<div class="box-name">性别：{{ date.refereeSex == 1 ? '男' : (date.refereeSex == 2 ? '女' :
				'') }}
								</div>
								<div class="box-name">级别：{{ date.certificateList ? date.certificateList.referee_level :
				'' }}</div>
								<div class="box-name">项目：{{ date.certificateList ? date.certificateList.sportName : ''
									}}
								</div>
								<div class="box-name">发证单位：{{ date.certificateList ?
				date.certificateList.certificate_unit : '' }}</div>
								<div class="box-name">注册所在区：{{ date.certificateList ?
				date.certificateList.certificate_area : '' }}</div>
							</div>
						</div>
						<!-- <div class="ststus">审核状态：2023年年审通过</div> -->
					</div>
				</div>

				<div style="background: #F8FBFF;padding: 0 20PX 20PX 20PX;">

					<div class="navt" style="width: auto;">
						<div class="navtxian"></div>
						<div class="navtname">执裁记录</div>
					</div>

					<div class="box" style="height: auto;">
						<div class="timeline" v-if="!this.$verification(this.date.enforcementList)">
							<el-timeline :reverse="reverse">
								<el-timeline-item v-for="(item, index) in date.enforcementList" :key="index"
									:timestamp="item.match_date" color="#0063FF">
									{{ item.match_name }}
								</el-timeline-item>
							</el-timeline>
						</div>
						<div v-if="this.$verification(this.date.enforcementList)" class="zx-box">
							<img class="zw" src="../assets/image/333.png" alt="">
							<div class="zwxx">暂无信息</div>
						</div>
					</div>

					<div class="navt" style="width: auto;">
						<div class="navtxian"></div>
						<div class="navtname">晋升记录</div>
					</div>

					<div class="promotionList">
						<div class="arbitration-box promotion">
							<el-timeline>
								<el-timeline-item placement="top" v-for="(item, index) in this.date.promotionList"
									:key="index">
									<div class="timelines">
										<div>
											<div class="jtxx-box">
												<div class="jtxxy">
													<div class="jb">
														<img src="../assets/image/111.png" alt="">
														<div style="flex-shrink: 0;">晋升级别：</div>
														<div>{{ item.refereeLevel }}</div>
													</div>
													<div class="rq">
														<img src="../assets/image/222.png" alt="">
														<div style="flex-shrink: 0;">发证日期：</div>
														<div>{{ item.certificateDate | format }}</div>
													</div>
												</div>
												<div class="dz">
													<img src="../assets/image/99.png" alt=""
														style="margin-right: 15PX;">
													<div style="flex-shrink: 0;">发证单位：</div>
													<div>{{ item.certificateUnit }}</div>
												</div>
											</div>
										</div>
									</div>
								</el-timeline-item>
							</el-timeline>

						</div>
					</div>

					<div v-if="this.$verification(this.date.promotionList)" class="zx-box">
						<img class="zw" src="../assets/image/333.png" alt="">
						<div class="zwxx">暂无信息</div>
					</div>
				</div>
				<!-- 
				<div style="margin-top: 19PX;">
					<div class="title">
						<img src="../assets/image/title2.png" alt="">
						<p>执裁记录</p>
					</div>
					<div class="box" style="height: auto;">
						<div class="timeline">
							<el-timeline :reverse="reverse">
								<el-timeline-item v-for="(item, index) in date.enforcementList" :key="index"
									:timestamp="item.match_date" color="#0063FF">
									{{ item.match_name }}
								</el-timeline-item>
							</el-timeline>
						</div>
					</div>
				</div>
				<div style="margin-top: 19PX;">
					<div class="title">
						<img src="../assets/image/title2.png" alt="">
						<p>晋升记录</p>
					</div>
					<div class="box" style="height: auto;">
						<div class="timeline">
							<el-timeline :reverse="reverse">
								<el-timeline-item v-for="(item, index) in date.promotionList" :key="index"
									:timestamp="item.certificateDate | format" color="#0063FF">
									{{ item.sportsName }}&nbsp;&nbsp;{{ item.refereeLevel }}证书&nbsp;&nbsp;{{
				item.certificateUnit }}</el-timeline-item>
							</el-timeline>
						</div>
					</div>
				</div> -->
				<!-- <div>
					<div class="title">
						<img src="../assets/image/title3.png" alt="">
						<p>相关报道</p>
					</div>
					<div class="box" style="height: auto;">
						<div class="none">暂无相关报道信息</div>
					</div>
				</div> -->
			</div>
			<div class="therightside">
				<div>
					<div class="title" style="width: 366PX;">
						<img src="../assets/image/title4.png" alt="">
						<p>相关裁判</p>
					</div>
					<div class="box" style="height: auto;width: 366PX;">
						<div class="berelatedto-box">
							<div class="berelatedto" v-for="(item, index) in refereeList" :key="index"
								@click="query(item)">
								<img :src="item.image" alt="" class="berelatedto-logo">
								<div class="berelatedto-name">{{ item.refereeName}}</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	name: '',
	components: {},
	data() {
		return {
			reverse: false,
			date: {
				certificateList: {},
				enforcementList: []
			},
			info: {
				pageNo: 1,
				pageSize: 12,
				refereeLevel: '',
				certificateArea: '',
				sports: '',
				refereeName: '',
				refereeSex: '',
				applyForStatus: '',
				startDate: '',
				endDate: '',
			},
			refereeList: [],
			titlename: '',
			options2: [],
			options3: [],
			id: ''
		}
	},
	filters: {
		format(x) {
			if (x != null && x != '') {
				let time = x.split(" ")[0]
				let parts = time.split('-');
				let result = parts[0] + '-' + parts[1];
				return result
			}
		}
	},
	created() {
		this.judgmentLevel()
		this.areaList()
		this.info.sports = this.$route.query.sports
		this.info.notInIds = this.$route.query.id
		setTimeout(() => {
			this.id = this.$route.query.id
		}, 1000)
		this.getRefereeDetails(this.$route.query.id)
		this.getRefereeBase()
		this.findAllColumn()
	},
	methods: {
		judgmentLevel() {
			this.$api.judgmentLevel().then(res => {
				this.options2 = res.data.data.result
			})
		},
		areaList() {
			this.$api.areaList().then(res => {
				this.options3 = res.data.data.result
			})
		},
		findAllColumn() {
			this.$api.findAllColumn({
				id: this.$route.query.columnId,
				pid: this.$route.query.columnPid
			}).then(res => {
				this.titlename = res.data.data.pid.name
			})
		},
		getRefereeDetails(e) {
			this.$api.getRefereeDetails({ refereeId: e }).then(res => {
				if (res.data.data.result.avatar) {
					this.$api.getFile({ objectName: res.data.data.result.avatar }).then(ess => {
						this.$convert(ess.data.data.result.url).then((fileUrl) => {
							res.data.data.result.avatar = fileUrl
							this.$forceUpdate()
						})
					})
				} else {
					res.data.data.result.avatar = require('@/assets/image/defaults.png')
					this.$forceUpdate()
				}
				if (res.data.data.result.certificateList && res.data.data.result.certificateList.length > 0) {
					res.data.data.result.certificateList = res.data.data.result.certificateList[0]
					for (let i in this.options2) {
						if (res.data.data.result.certificateList.referee_level == this.options2[i].itemValue) {
							res.data.data.result.certificateList.referee_level = this.options2[i].itemText
						}
					}
					for (let i in this.options3) {
						if (res.data.data.result.certificateList.certificate_area == this.options3[i].itemValue) {
							res.data.data.result.certificateList.certificate_area = this.options3[i].itemText
						}
					}
				}
				res.data.data.result.enforcementList.forEach(item => {
					let match_date = JSON.parse(item.match_date)
					let arr = match_date.map(item => { return item.startDate + ' 至 ' + item.endDate })
					item.match_date = arr.join("，")
				})
				res.data.data.result.promotionList.forEach(item => {
					item.certificateUnit = this.$decrypt(item.certificateUnit || '')
				})
				res.data.data.result.refereeName = this.$decrypt(res.data.data.result.refereeName || '')
				res.data.data.result.certificateList.certificate_unit = this.$decrypt(res.data.data.result.certificateList.certificate_unit || '')
				this.date = res.data.data.result

			})
		},
		getRefereeBase() {
			this.$api.getRefereeBase(this.info).then(res => {
				res.data.data.result.records.forEach(item => {
					if (item.avatar) {
						this.$api.getFile({ objectName: item.avatar }).then(ess => {
							this.$convert(ess.data.data.result.url).then((fileUrl) => {
								item.image = fileUrl
								this.$forceUpdate()
							})
						})
					} else {
						item.image = require('@/assets/image/defaults.png')
						this.$forceUpdate()
					}
				})
				res.data.data.result.records.forEach(item => {
					item.refereeName = this.$decrypt(item.refereeName)
				})
				this.refereeList = res.data.data.result.records
			})
		},
		query(item) {
			this.info.sports = item.sports
			setTimeout(() => {
				this.id = item.refereeId
			}, 1000)
			this.info.notInIds = item.refereeId
			this.getRefereeDetails(item.refereeId)
			this.getRefereeBase()
			window.scrollTo(0, 0)
		}
	}
}
</script>

<style lang="scss" scoped>
.header {
	display: flex;
	width: 1200PX;
	margin: 34PX auto 0;
	font-size: 18PX;
	font-weight: 500;
	color: #333333;
	cursor: pointer;
}

.current {
	color: #0066FF;
}

.back {
	width: 1200PX;
	margin: 0 auto;
	display: flex;
	margin-bottom: 100PX;
}

.theleftside {}

.title {
	width: 836PX;
	height: 57PX;
	background: #D8E7FF;
	border-radius: 22PX 22PX 0PX 0PX;
	display: flex;
	align-items: center;
}

.top {
	margin-top: 39PX;
}

.title img {
	width: 31.4PX;
	height: 38.1PX;
	margin-left: 68PX;
}

.title p {
	font-size: 25PX;
	font-weight: 500;
	color: #0063FF;
	line-height: 36PX;
	margin-left: 33PX;
}

.box {
	// width: 836PX;
	// height: 473PX;
	height: 415PX;
	background: #F8FBFF;
}

.box-back {
	width: 685PX;
	height: 310PX;
	background: url('../assets/image/coach.png');
	background-repeat: no-repeat;
	background-size: 100% 100%;
	margin-left: 74PX;
	display: flex;
}

.box-logo {
	width: 158PX;
	height: 219PX;
	border-radius: 18PX;
	margin-top: 48PX;
	margin-left: 75PX;
}

.box-title {
	margin-left: 66PX;
	margin-top: 45PX;
}

.box-name {
	font-size: 18PX;
	font-weight: 400;
	color: #000;
	margin-bottom: 9PX;
}

.ststus {
	font-size: 21PX;
	font-weight: 400;
	color: #0063FF;
	margin-top: 33PX;
	margin-left: 130PX;
}

.none {
	font-size: 22PX;
	font-weight: 400;
	color: #ACACAC;
	margin-left: 93PX;
	padding: 36PX;
}

.timeline {
	padding: 40PX 40PX 0;
}

::v-deep .el-timeline-item__tail {
	border-left: 2PX solid #0063FF !important;
}

::v-deep .el-timeline-item__content {
	font-size: 25PX;
	font-weight: 400;
	color: #000000;
}

::v-deep .el-timeline-item__timestamp {
	font-size: 22PX;
	font-weight: 400;
	color: #ACACAC;
}

.therightside {
	margin-top: 39PX;
	margin-left: 39PX;
}

.berelatedto-box {
	width: 366PX;
	display: flex;
	justify-content: flex-start;
	flex-wrap: wrap;
	margin-left: 29PX;
	padding-top: 49PX;
}

.berelatedto {
	margin-bottom: 27PX;
	margin-right: 25PX;
}

.berelatedto:nth-child(2n) {
	margin-right: 0;
}

.berelatedto-logo {
	width: 144PX;
	height: 169PX;
	border-radius: 18PX;
}

.berelatedto-name {
	font-size: 22PX;
	font-weight: 400;
	color: #000000;
	margin-top: 13PX;
	width: 144PX;
	text-align: center;
}

.titlename:hover {
	color: #0066FF;
}

::v-deep .el-timeline-item__timestamp {
	font-size: 20PX;
	line-height: 23PX;
}

.navt {
	width: 836PX;
	height: 58PX;
	background: #FFFFFF;
	box-shadow: 5PX 14PX 18PX 3PX rgba(170, 202, 237, 0.16);
	border-radius: 19PX;
	display: flex;
	align-items: center;

	.navtxian {
		width: 9PX;
		height: 23PX;
		background: #2A7AFF;
		border-radius: 5PX;
		margin-left: 38PX;
	}

	.navtname {
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 26PX;
		color: #2A7AFF;
		margin-left: 21PX;
	}
}

.zx-box {
	margin-top: 40PX;
	display: flex;
	flex-direction: column;
	align-items: center;

	.zw {
		height: 162PX;
	}

	.zwxx {
		font-family: SourceHanSansCN;
		font-weight: 500;
		font-size: 22PX;
		color: #989898;
		margin-top: 27PX;
		margin-bottom: 40PX;
	}
}

.arbitration-box {

	.arbitration {
		min-width: 115PX;
		height: 38PX;
		background: linear-gradient(150deg, #0D63FF, #3280FF);
		border-radius: 19PX 19PX 19PX 0PX;
		display: inline-block;
		cursor: pointer;
		line-height: 38PX;
		margin-bottom: 29PX;
		padding: 0 19PX;
	}

	.arbitrationname {
		font-family: SourceHanSansCN;
		font-weight: bold;
		font-size: 20PX;
		color: #FFFFFF;
		margin: 0 19PX;
		display: inline-block;
	}

	.triangle-down {
		width: 0;
		height: 0;
		border-left: 4PX solid transparent;
		/* 左边框为透明，宽度为4PX */
		border-right: 4PX solid transparent;
		/* 右边框为透明，宽度为4PX */
		border-top: 7PX solid #FFFFFF;
		/* 上边框为白色，高度为7PX */
		display: inline-block;
		position: relative;
		top: -2PX;
	}

	.triangle-right {
		width: 0;
		height: 0;
		border-top: 4PX solid transparent;
		/* 上边框为透明，高度为4PX */
		border-bottom: 4PX solid transparent;
		/* 下边框为透明，高度为4PX */
		border-left: 7PX solid #FFFFFF;
		/* 左边框为白色，宽度为7PX */
		display: inline-block;
		position: relative;
		top: -2PX;
	}

	// .timeline {
	// 	margin-top: 20PX;
	// 	margin-left: 45PX;

	// 	::v-deep .el-timeline-item__content {
	// 		font-family: SourceHanSansCN;
	// 		font-weight: 500;
	// 		font-size: 19PX;
	// 		color: #000000;
	// 	}

	// 	::v-deep .el-timeline-item__timestamp {
	// 		font-family: SourceHanSansCN;
	// 		font-weight: 500;
	// 		font-size: 15PX;
	// 		color: #9B9B9B;
	// 	}

	// 	::v-deep .el-timeline-item__tail {
	// 		border-left: 2PX solid #0063FF !important;
	// 	}

	// 	::v-deep .el-timeline-item {
	// 		padding-bottom: 40PX !important;
	// 	}
	// }
}

.promotion {
	border-radius: 19PX;

	.timelines {
		.jtxx-box {
			// margin-left: 35PX;

			.jtxxy {
				display: flex;
				align-items: center;
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 19PX;
				color: #000000;

				.jb {
					display: flex;

					img {
						width: 20.2PX;
						height: 26.3PX;
					}

					div:nth-child(3) {
						color: #2D7CFF;
					}
				}

				.rq {
					display: flex;
					position: absolute;
					margin-left: 282PX;

					img {
						width: 22.1PX;
						height: 20.5PX;
					}
				}
			}

			.dz {
				display: flex;
				align-items: center;
				font-family: SourceHanSansCN;
				font-weight: 500;
				font-size: 19PX;
				color: #000000;
				margin-top: 33PX;

				img {
					width: 26PX;
					height: 20PX;
				}

			}

			img {
				margin-right: 20PX;
			}
		}

		.jtxxxian {
			background: #D3D3D3;
			border-radius: 0PX;
			margin: 30PX 35PX;
		}

		.jtxxkb {
			padding-bottom: 35PX;
			margin-bottom: 35PX;
		}
	}
}

.promotionList {
	margin-top: 25PX;
	background: #fff;
	padding: 40PX 40PX 0 40PX;
	border-radius: 15PX;
}
::v-deep .el-timeline-item__timestamp.is-top{
	display: none;
}
.el-timeline-item{
	padding-bottom: 40PX !important;
}
::v-deep .el-timeline-item__node{
 	background: #2A7AFF !important;
}
</style>
